import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
    <SEO title="Home" />
    <div className="section bg-primary overflow-hidden">
          <div className="container z-2">
              <div className="row my-6 my-md-4 pb-5 text-white text-center justify-content-center">
                  <div className="col-12 col-md-10">
                      <p className="my-4 px-0 px-lg-7 lead"><b>Willkommen auf dem Hotspot-Portal von Zorrilla Media</b></p>
                  </div>
              </div>
          </div>
      </div>
      <section className="section pt-0">
          <div className="container">
              <div className="col-12">
                  <div className="row justify-content-center mt-n7 z-2">
                      <div className="col-12 col-md-8">
                          <div className="card border-light">
                              <div className="card-body p-5">
                                <div className="d-flex flex-column"> 
                                  <p className="my-4 px-0 px-lg-7 lead text-center justify-content-center">Diese Anwendung erlaubt die Autorisierung von neuen Geräten im Gäste-Netzwerk</p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
   </Layout>
  );
};

export default IndexPage
